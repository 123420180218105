<template>
  <header class="navigation-top">
    <div class="row">
      <div class="col-sm-12">
        <nav id="navigator" class="navbar" :class="{navbaropen: opened}">
          <span class="open-slide">
            <a href="#" @click="opened = !opened">
              <div>
                <div class="bar" :class="{topopen: opened}"></div>
                <div class="bar" :class="{midopen: opened}"></div>
                <div class="bar" :class="{botopen: opened}"></div>
              </div>
            </a>
          </span>
          <span class="logo"><img src="https://s3.eu-west-2.amazonaws.com/assets.xonder.co.uk/logo/logo.svg"></span>
          <div class="bread-come" v-if="getBreadCums.breadcums">
            <template v-if="(Object.keys(getBreadCums.breadcums.parent).length)">
              <span class="bread-come" > 
                <router-link
                  :to="{
                    path:  getBreadCums.breadcums.parent.path
                  }"
                  tag="a"
                >
                  {{ getBreadCums.breadcums.parent.title }}
                </router-link>
              </span> 
            </template>
            <span class="bread-come" :class="getBreadCums.breadcums.disabled ? 'normal-title' : ''">
              {{ getBreadCums.breadcums.title }}
            </span>
          </div>
          <div class="bread-come" v-else>
            <span class="bread-come">Home</span>
          </div>
        </nav>
        <div id="side-menu" class="side-nav" :class="{sidenavopen: opened}">
          <router-link
            :to="{
              path: '/home'
            }"
            tag="a"
          >
            Home
          </router-link>
          <router-link
            :to="{
              path: '/customers'
            }"
            tag="a"
          >
          Customers
          </router-link>
          <router-link
            :to="{
              path: '/kyb-requests'
            }"
            tag="a"
          >
          Kyb Request List
          </router-link>
          <router-link
            :to="{
              path: '/countries-list'
            }"
            tag="a"
          >
          Counties List
          </router-link>

          <router-link
            :to="{
              path: '/all-documents'
            }"
            tag="a"
          >
            All Documents
          </router-link>


          <!-- Collapse 2 -->
          <!-- <a
             @click="onToggle" :class="(add_class) ? 'collapsed-brd' : ''" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"
            >
              Documents
            </a> -->
            <!-- Collapsed content -->
            <!-- <div class="collapse" id="collapseExample">
              <router-link
                :to="{
                  path: '/documents/additional-documents'
                }"
                tag="a"
              >
                Additional Doccuments
              </router-link>

              <router-link
                :to="{
                  path: '/documents/business-contact-documents'
                }"
                tag="a"
              >
                Business Contact Document
              </router-link>

              <router-link
                :to="{
                  path: '/documents/business-documents'
                }"
                tag="a"
              >
                Business Document
              </router-link>

              <router-link
                :to="{
                  path: '/all-documents'
                }"
                tag="a"
              >
                All Documents
              </router-link>
              
            </div> -->
            <!-- Collapse 2 -->




          
          <router-link
            :to="{
              path: '/rejected-reasons'
            }"
            tag="a"
          >
          Rejected Reasons
          </router-link>
          <router-link
            :to="{
              path: '/verification-documents-types'
            }"
            tag="a"
          >
          Additional Documents Templates
          </router-link>
          <router-link
            :to="{
              path: '/verification-documents-list-items'
            }"
            tag="a"
          >
            Additional Documents Templates List Items
          </router-link>
          <router-link
            :to="{
              path: '/ticket'
            }"
            tag="a"
          >
          Tickets
          </router-link>
          <router-link
            :to="{
              path: '/document-upload'
            }"
            tag="a"
          >
          Upload Documents
          </router-link>
          <router-link
            :to="{
              path: '/additional-documents'
            }"
            tag="a"
          >
          Additional Documents
          </router-link>
          <router-link
            :to="{
              path: '/payee-fraud-warning-headings'
            }"
            tag="a"
          >
          Payee Fraud Warning Headings
          </router-link>
          <router-link
            :to="{
              path: '/payee-fraud-warning-guide'
            }"
            tag="a"
          >
          Payee Fraud Warnings Guide (Icon)
          </router-link>
          <router-link
            :to="{
              path: '/custom-categories'
            }"
            tag="a"
          >
          
          Add Custom Categories
          </router-link>
          <router-link
            :to="{
              path: '/custom-title-for-additional-documents'
            }"
            tag="a"
          >
          Add Additional Document Title
          </router-link>
          <router-link
            :to="{
              path: '/custom-description-for-additional-documents'
            }"
            tag="a"
          >
          Add Additional Document Description
          </router-link>
          <router-link
            :to="{
              path: '/emails/templates'
            }"
            tag="a"
          >
          Email Templates
          </router-link>
          <router-link
            :to="{
              path: '/emails/auto'
            }"
            tag="a"
          >
          Email List
          </router-link>
          <router-link
            :to="{
              path: '/faq'
            }"
            tag="a"
          >
          Faq
          </router-link>
          <router-link
            :to="{
              path: '/faq-categories'
            }"
            tag="a"
          >
          Faq Categories
          </router-link>
          <router-link
            :to="{
              path: '/transactions'
            }"
            tag="a"
          >
          Transactions
          </router-link>
          <router-link
            :to="{
              path: '/login-logs'
            }"
            tag="a"
          >
          Registered Devices
          </router-link>
          <router-link
            :to="{
              path: '/login-logs'
            }"
            tag="a"
          >
            Registered Devices
          </router-link>
          <router-link
            :to="{
              path: '/reports/transactions'
            }"
            tag="a"
          >
          Reports - Users with Zero transaction
          </router-link>
          <router-link
            :to="{
              path: '/settings'
            }"
            tag="a"
          >
            Settings
          </router-link>
          <a @click.prevent="logOutSection" href="#"
          >
            Logout
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// import settingsNav from "../admin-setting-nav";
export default {
  data() {
    return {
      pre: "",
      homepre: "",
      isShow: false,
      opened: false,
      add_class : false,
    };
  },
  components: {
    // settingsNav: settingsNav
  },
  computed: {
    getUserData() {
      return this.$store.state.auth;
    },
    getBreadCums() {
      return this.$router.app._route.meta;
    }
  },
  mounted() {},
  methods: {
    onToggle() {
      if(this.add_class) {
        this.add_class = false;
      } else {
        this.add_class = true;
      }
    },
    logOutSection() {
      this.$store.dispatch("generalLoader", {
        response: true
      });
      this.$store.dispatch("logOutSection");
    }
  }
};
</script>

<style lang="scss" scoped>

span.bread-come.normal-title {
    font-weight: normal;
}
span.bread-come a:after {
    content: "/";
}
span.bread-come {
    display: inline-block;
    vertical-align: middle;
    padding-left: 5px;
}

span.bread-come {
    color: #fff;
    font-weight: bold;
}
nav#navigator {
      width: 100%;
  
      .navbar ul[data-v-519b0f42] {
          margin: 0 0 0 0;
          list-style: none;
      }
  
      .navbar-nav {
          display: flex;
          flex-direction: row;
          padding-left: 0;
          margin-bottom: 0;
          list-style: none;
      }
  }
  
  .navbar{
    background-color: #3b5998;
    overflow: hidden;
    height: 63px;
  }
  
  .navbaropen{
    background-color: #3b5998;
    overflow: hidden;
    height: 63px;
    margin-left: 250px;
  }
  
  .navbar a{
    float: left;
    display: block;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-size: 17px;
  }
  
  .navbar ul{
    margin: 8px 0 0 0;
    list-style: none;
  }
  
  .navbar a:hover{
    // background-color: #ddd;
    // color: #000;
  }
  
  .side-nav{
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    opacity: 0.9;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.3s;
  }
  
  .sidenavopen{
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    opacity: 0.9;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.3s;
  }
  
  .side-nav a{
    padding: 10px 10px 10px 30px;
    text-decoration: none;
    font-size: 15px;
    color: #ccc;
    display: block;
    transition: 0.3s;
    border-bottom: 1px solid #ccc;
  }
  
  .side-nav a:hover{
    color: #fff;
  }
  
  .side-nav .btn-close{
    position: absolute;
    top: 0;
    right: 22px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  
  nav{
    transition: margin-left 0.3s;
  }
  
  .bar{
    display: block;
    height: 5px;
    width: 35px;
    background: #fff;
    margin: 5px auto;
  }

  .bar{
    transition: all .3s ease;
  }
  .mainopen{
    transition: margin-left 0.3s;
    padding: 20px;
    overflow: hidden;
    width: 100%;
    margin-left: 250px;
  }
  .midopen{
    width: 0;
  }
  .topopen{
    transform: translateY(10px) rotateZ(45deg);
  }
  .botopen{
    transform: translateY(-10px) rotateZ(-45deg);
  }
  a.collapsed-brd {
      border-bottom: none !important;
  }
  div#collapseExample a {
    padding-left: 50px !important;
}
</style>
