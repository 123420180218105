/* eslint-disable */
import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
// Import Font Awesomne js Files
import '@fortawesome/fontawesome-free/js/all.js';

import 'bootstrap';
// import jQuery from 'jquery';
// window.$ = window.jQuery = jQuery;
import './assets/css/app.scss';

import axios from 'axios'
import VueAxios from 'vue-axios'
window.axios = axios;
Vue.use(VueAxios, axios)
window.Vue = Vue;


import VueLazyload from 'vue-lazyload'
// or with options
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '/images/group-3739.png',
  loading: '/images/RigidPaltryAntelope-max-1mb.gif',
  attempt: 1
})

import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';

Vue.filter('numFormat', numFormat(numeral));

import CKEditor from 'ckeditor4-vue-color-v1';

Vue.use( CKEditor );

import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
Vue.use(VModal)

import VuejsDialog from 'vuejs-dialog';
import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; // only needed in custom components
 
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
 
// Tell Vue to install the plugin.
Vue.use(VuejsDialog);

Vue.config.productionTip = false;

Vue.component('pagination', require('laravel-vue-pagination'));

import VueProgressBar from 'vue-progressbar'
const options = {
  color: '#bffaf3',
  failedColor: '#00FF00',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}
 
Vue.use(VueProgressBar, options)


// Live 
// window.mobileappurl = 'https://api.xonder.co.uk/storage';
// window.homeurl = 'https://webadmin.xonder.co.uk';
// window.api_homeurl = 'https://webadmin.xonder.co.uk/api/v1';
// window.app_url = 'https:/webportal.xonder.co.uk';
// window.parent_app_url = 'https:/webportal.xonder.co.uk';

// Pre Live 
// window.mobileappurl = 'https://stgpro.xonder.co.uk/storage';
// window.homeurl = 'https://prewebadmin.xonder.co.uk';
// window.api_homeurl = 'https://prewebadmin.xonder.co.uk/api/v1';
// window.app_url = 'https:/prewebportal.xonder.co.uk';
// window.parent_app_url = 'https:/prewebportal.xonder.co.uk';


// Staging 
window.app_url = 'https://stagingwebportal.xonder.co.uk';
window.mobileappurl = 'https://staging.xonder.co.uk/storage';
window.homeurl = 'https://stagingwebadmin.xonder.co.uk';
window.api_homeurl = 'https://stagingwebadmin.xonder.co.uk/api/v1';
window.parent_app_url = 'https://stagingwebportal.xonder.co.uk';


// Local Url For Shafi
// window.mobileappurl = 'http://modularmobileapp.test';
// window.homeurl = 'http://webportal.modularmobileapp.test';
// window.api_homeurl = 'http://webportal.modularmobileapp.test/api/v1';
// window.app_url = 'http://modularmobileapp.test:8080';
// window.parent_app_url = 'http://modularmobileapp.test:8080';



axios.defaults.baseURL =  window.api_homeurl;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// local
axios.defaults.headers.common['xon-auth'] = 'xon:a8de1bcb8ca269f818730da8c92ca3ef:der';
// production
// axios.defaults.headers.common['xon-auth'] = 'xon:9b66dcf26891eebb8b5d0dfeaa7c3d86:der';
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;


new Vue({
  store,
  router,
  component: {
  },
  async mounted() {
    await axios.get(window.homeurl+"/api/user").then(resps => {
      this.$store.dispatch("checkIfUserAlreadyLogedIn", {
          response: resps,
        });
    }).catch(error => {
      this.$store.dispatch("checkIfUserAlreadyLogedIn", {
        response: error.response,
      });
    });
  },
  render: h => h(App)
}).$mount("#app");