/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import store from '../store/index';
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(Vuex);
Vue.use(VueRouter);

function guard(to, from, next){
  if(store.state.auth.loggedIn) {
    if(to.name === 'Login') {
        next('/home');
    } else {
      next(); // allow to enter route
    }
  } else{
      if(to.name !== 'Login') {
          next('/login');
      } else {
        next(); // allow to enter route
      }
  }
}

const routes = [
  {
		path: '*',
		meta: {
			pageTitle: 'No Component Found'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/noComponentFound.vue")
	},
  {
    path: "/",
    redirect: to => {
      return '/login';
    }
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      pageTitle: 'Login',
      public: true,
		},
    // beforeEnter: guard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue")
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    meta: {
      pageTitle: 'Forget Password',
      public: true,
		},
    // beforeEnter: guard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "ForgetPassword" */ "../views/forget-password.vue")
  },
  {
    path: "/password/reset",
    name: "password.reset",
    meta: {
      pageTitle: 'Reset Password',
      public: true,
		},
    // beforeEnter: guard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "ForgetPassword" */ "../views/reset-password.vue")
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      breadcums: {
        title  : 'Home',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Home',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/dashboard/dashboard_index.vue")
  },
  {
    path: "/customers",
    name: "Customers",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Customers',
        path : '',
        disabled : true,
        parent : {},
      },
      pageTitle: 'Customers',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Customers" */ "../views/customers/index")
  },

  {
    path: "/customers/show/:id",
    name: "customers.details",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Details',
        disabled : true,
        path : '',
        parent : {
          title  : 'Customers',
          path : '/customers',
          disabled : false,
        },
      },
      pageTitle: 'Customers Details',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "customers.details" */ "../views/customers/show/index")
  },
  {
    path: "/emails/templates",
    name: "template.index",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Email Templates',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Email Templates',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Customers" */ "../views/email-templates/index")
  },
  {
    path: "/emails/templates/show/:id",
    name: "template.edit",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Details',
        disabled : true,
        path : '',
        parent : {
          title  : 'Email Templates',
          path : '/emails/templates',
          disabled : false,
        },
      },
      pageTitle: 'Email Template - Edit',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "customers.details" */ "../views/email-templates/show/index")
  },
  {
    path: "/emails/templates/create",
    name: "template.create",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Create',
        disabled : true,
        path : '',
        parent : {
          title  : 'Email Templates',
          disabled : false,
          path : '/emails/templates',
        },
      },
      pageTitle: 'Email Template - Create',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "customers.details" */ "../views/email-templates/create/index")
  },
  {
    path: "/emails/templates/edit/:id",
    name: "template.edit",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Edit',
        disabled : true,
        path : '',
        parent : {
          title  : 'Email Templates',
          disabled : false,
          path : '/emails/templates/edit/:id',
        },
      },
      pageTitle: 'Email Template - Edit',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "customers.details" */ "../views/email-templates/edit/index")
  },
  {
    path: "/emails/auto",
    name: "auto.email.index",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Email Auto',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Auto Emails List',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Customers" */ "../views/email-templates/autoEmails")
  },

  {
    path: "/emails/chains",
    name: "chain.index",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Email Chains',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Email Templates',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Customers" */ "../views/email-chains/index")
  },
  {
    path: "/emails/chains/create",
    name: "chain.create",
    // beforeEnter: guard,
    meta: {
      pageTitle: 'Email Template - Create',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "customers.details" */ "../views/email-chains/create/index")
  },
  {
    path: "/emails/chains/show/:id",
    name: "chain.edit",
    // beforeEnter: guard,
    meta: {
      pageTitle: 'Email Chain - Edit',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "customers.details" */ "../views/email-chains/show/index")
  },

  {
    path: "/document-upload",
    name: "Document Upload",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Document Upload',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Document Upload',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "DocumentUpload" */ "../views/UploadDocuments/index")
  },

  {
    path: "/additional-documents",
    name: "AdditionalDocuments",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Additional Documents',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Additional Documents',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "AdditionalDocuments" */ "../views/additional-documents/index")
  },
//payee heading
  {
    path: "/payee-fraud-warning-headings",
    name: "Payee-Fraud-Warning-Headings",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Payee Fraud Warning Headings',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Payee Fraud Warning Headings',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    component: () =>
      import(/* webpackChunkName: "AdditionalDocuments" */ "../views/payee-fraud-warning-heading/index")
  },
  //payee heading edit
  {
    path: "/payee/headings/edit/:id",
    name: "payee.edit",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Edit',
        disabled : true,
        path : '',
        parent : {
          title  : 'Payee Heading',
          disabled : false,
          path : '/payee/headings/edit/:id',
        },
      },
      pageTitle: 'Payee Heading - Edit',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "customers.details" */ "../views/payee-fraud-warning-heading/edit/index")
  },
    //Payee with icon
  {  
      path: "/payee-fraud-warning-guide",
      name: "Payee-Fraud-Warning-Guide",
      // beforeEnter: guard,
      meta: {
        breadcums: {
          title  : 'Payee Fraud Warnings Guide',
          disabled : true,
          path : '',
          parent : {},
        },
        pageTitle: 'Payee Fraud Warning Guide',
        public: false,
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00FF00'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
          ]
        }
      },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "AdditionalDocuments" */ "../views/payee-fraud-warning-guide/index")
  },

   //payee heading edit
   {
    path: "/payee/guide/edit/:id",
    name: "payee.edit",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Edit',
        disabled : true,
        path : '',
        parent : {
          title  : 'Payee Guide Edit',
          disabled : false,
          path : '/payee/guide/edit/:id',
        },
      },
      pageTitle: 'Payee Guide - Edit',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "customers.details" */ "../views/payee-fraud-warning-guide/edit/index")
  },


  {
    path: "/ticket",
    name: "Ticket",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Tickets',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Ticket',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Ticket" */ "../views/ticket-system/index")
  },

  {
    path: "/ticket/show/:ticket_id",
    name: "Ticket",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Details',
        disabled : true,
        path : '',
        parent : {
          title  : 'Tickets',
          path : '/ticket',
          disabled : false,
        },
      },
      pageTitle: 'Ticket',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TicketSystem" */ "../views/ticket-system/show")
  },

  {
    path: "/custom-categories",
    name: "CustomCategories",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Custom Categories',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Custom Categories',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "CustomCategories" */ "../views/custom-categories/index")
  },

  {
    path: "/custom-title-for-additional-documents",
    name: "CustomTitleForAdditionalDocuments",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Additional Documents Title',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Custom Title For Additional Documents',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "CustomTitleForAdditionalDocuments" */ "../views/additional-documents-title/index")
  },

  {
    path: "/custom-description-for-additional-documents",
    name: "CustomDescriptionForAdditionalDocuments",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Additional Documents Description',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Custom Title For Additional Documents',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "CustomDescriptionForAdditionalDocuments" */ "../views/additional-documents-description/index")
  },

  {
    path: "/faq",
    name: "FAQ",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'FAQ',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'FAQ',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "FAQ" */ "../views/faq/index")
  },

  {
    path: "/faq-categories",
    name: "FAQ Categories",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'FAQ Categories',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'FAQ Categories',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "FAQCategories" */ "../views/faq-category/index")
  },
  {
    path: "/transactions",
    name: "Transactions",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Transactions',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Transactions',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Transactions" */ "../views/transactions/index")
  },
  {
    path: "/login-logs",
    name: "LoginLogs",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Login Logs',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Login Logs',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "LoginLogs" */ "../views/user-login-logs/index")
  },

  {
    path: "/verification-documents-types",
    name: "VerificationDocumentsType",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Verification Documents Type',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Verification Documents Type',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "VerificationDocumentsType" */ "../views/verification-documents-title/index")
  },

  {
    path: "/verification-documents-types/create",
    name: "VerificationDocumentsTypeCreate",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Verification Documents Type - Create',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Verification Documents Type  - Create',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "VerificationDocumentsTypeCreate" */ "../views/verification-documents-title/create/index")
  },

  {
    path: "/verification-documents-types/edit/:id",
    name: "VerificationDocumentsTypeEdit",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Verification Documents Type - Edit',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Verification Documents Type  - Edit',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "VerificationDocumentsTypeEdit" */ "../views/verification-documents-title/edit/index")
  },

  {
    path: "/verification-documents-type-list",
    name: "VerificationDocumentsTypeList",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Verification Documents Type',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Verification Documents Type',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "VerificationDocumentsTypeList" */ "../views/verification-documents-title-list/index")
  },

  {
    path: "/reports/transactions",
    name: "TransactionReports",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Report - Users with zero transaction',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Report - Users with zero transaction',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TransactionReports" */ "../views/reports/by-transaction/index")
  },

  {
    path: "/rejected-reasons",
    name: "RejectedReasons",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Rejected Reasons',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Rejected Reasons',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "RejectedReasons" */ "../views/rejected-reasons/index")
  },



  {
    path: "/verification-documents-list-items",
    name: "VerificationDocumentsListItems",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Verification Documents List Items',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Verification Documents List Items',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "VerificationDocumentsListItems" */ "../views/verification-document-list-items/index")
  },
  
  {
    path: "/countries-list",
    name: "CountriesList",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Countries List',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Countries List',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "CountriesList" */ "../views/country/index")
  },

  {
    path: "/documents/additional-documents",
    name: "DocumentsAdditionalDocs",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Additional Documents',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Additional Documents',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "DocumentsAdditionalDocs" */ "../views/documents/index")
  },

  {
    path: "/documents/business-contact-documents",
    name: "BusinessContactDocuments",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Business Contact Documents',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Business Contact Documents',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "BusinessContactDocuments" */ "../views/business-contact-documents/index")
  },

  {
    path: "/documents/business-documents",
    name: "BusinessDocuments",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Business Documents',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Business Documents',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "BusinessDocuments" */ "../views/business-documents/index")
  },

  {
    path: "/all-documents",
    name: "AllBusinessDocuments",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Business Documents',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Business Documents',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "AllBusinessDocuments" */ "../views/all-documents/index")
  },

  {
    path: "/kyb-requests",
    name: "KybRequests",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Kyb Requests',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'Kyb Requests',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "KybRequests" */ "../views/kyb-requests/index")
  },

  {
    path: "/settings",
    name: "Settings",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Settings',
        disabled : true,
        path : '',
        parent : {},
      },
      pageTitle: 'FAQ',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Settings" */ "../views/settings/index.vue")
  },
  {
    path: "/groups/create",
    name: "group.create",
    // beforeEnter: guard,
    meta: {
      breadcums: {
        title  : 'Details',
        disabled : true,
        path : '',
        parent : {
          title  : 'Email Templates',
          path : '/emails/templates',
          disabled : false,
        },
      },
      pageTitle: 'Email Template - Edit',
      public: false,
      progress: {
        func: [
          {call: 'color', modifier: 'temp', argument: '#00FF00'},
          {call: 'fail', modifier: 'temp', argument: '#6e0000'},
          {call: 'location', modifier: 'temp', argument: 'top'},
          {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '0.6s', termination: 400}}
        ]
      }
		},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "customers.details" */ "../views/email-templates/show/index")
  },

];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
